// Base
import * as React from 'react'

// Components
import { Button, Link } from '@mui/material'

const CtaButton = ({ content, children }) => {
  const { text, component, href, ...attrs } = content
  return component === 'Link' ? (
    <Link
      href={
        href === 'SIGNUP'
          ? `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`
          : href
      }
      {...attrs}
    >
      {text}
      {children}
    </Link>
  ) : (
    <Button
      variant='contained'
      href={
        href === 'SIGNUP'
          ? `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`
          : href
      }
      {...attrs}
    >
      {text}
      {children}
    </Button>
  )
}
export default CtaButton
