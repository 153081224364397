import * as React from 'react'
import Parser from 'html-react-parser'

// Styles
import './criteriasTable.scss'

// Components
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

const CriteriasTable = ({ content }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label='simple table' className='criteria-table'>
        {content.headers.length > 0 && (
          <TableHead>
            <TableRow>
              {content.headers.map((header, i) => (
                <TableCell key={i} className='fw-bold header-purple'>
                  <Typography component='div' variant='h4'>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {content.rows.map((row) => (
            <TableRow key={row.criteria}>
              <TableCell component='th' scope='row' className='fw-bold criteria-column'>
                {row.criteria}
              </TableCell>
              <TableCell className='definition-column'>{row.definition}</TableCell>
              <TableCell>{Parser(row.procedure)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CriteriasTable
