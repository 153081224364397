// Base
import * as React from 'react'

// Components
import { Typography, Stack, Button } from '@mui/material'
import Parser from 'html-react-parser'
import CtaButton from '../CtaButton'

// Styling
import './backgroundImgCard.scss'

const BackgroundImgCard = ({ content }) => {
  const backgroundImage = {
    background: 'url(' + content.backgroundImage + ')',
  }

  return (
    <div
      style={backgroundImage}
      className={
        'background-image-card d-flex flex-column align-items-center justify-content-center ' + content.extraClass
      }
    >
      {content?.subhead && (
        <Typography variant='subhead' sx={{ display: 'block', color: 'bluishGrey.four', mb: 3 }}>
          {content.subhead}
        </Typography>
      )}
      {content?.title && (
        <Typography variant='h2' sx={{ color: '#fff', textShadow: (theme) => theme.textShadows[3], mb: 3 }}>
          {Parser(content.title)}
        </Typography>
      )}

      {content?.subtitle_first && (
        <Typography variant='h3' sx={{ color: '#fff', textShadow: (theme) => theme.textShadows[3], mb: 3 }}>
          {Parser(content.subtitle_first)}
        </Typography>
      )}

      {content?.subtitle_second && (
        <Typography variant='h3' sx={{ color: '#fff', textShadow: (theme) => theme.textShadows[3], mb: 3 }}>
          {Parser(content.subtitle_second)}
        </Typography>
      )}

      {content?.text && (
        <Typography variant='body1Bold' sx={{ color: '#fff', textShadow: (theme) => theme.textShadows[3], mb: 3 }}>
          {Parser(content.text)}
        </Typography>
      )}

      {content.cta && (
        <Button size='small' variant='contained' href={content.cta.url} target='_blank' rel='noopener'>
          {content.cta.text}
        </Button>
      )}
      {content?.buttons && (
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: { xs: '200px', sm: 'auto' }, mx: 'auto' }}
          spacing={1}
          justifyContent='center'
        >
          {content.buttons.map((button, i) => (
            <CtaButton key={i} content={button} />
          ))}
        </Stack>
      )}
    </div>
  )
}
export default BackgroundImgCard
