import * as React from 'react'
import styled from 'styled-components'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.bluishGrey.one,
    padding: 16,
    maxWidth: 220,
  },
}))
