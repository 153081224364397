// Base
import * as React from 'react'
import Parser from 'html-react-parser'

// Components
import { Box, Card, Typography } from '@mui/material'

// Styles
import './BackgroundCardIspo.scss'

const BackgroundCardIspo = ({ content }) => {
  return (
    <Card
      variant='outlined'
      sx={{
        position: 'relative',
        display: 'block',
        height: 374,
        backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.4) 100%), url('${content?.background}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center -27px',
        '&:hover': {
          '& .overlay': {
            opacity: 1,
          },
          '& .MuiTypography-body2': {
            opacity: 1,
            transform: 'translateY(0)',
          },
        },
      }}
    >
      <Box
        className='overlay'
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.4) 100%), url('${content?.background}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center -27px',
          opacity: 0,
          transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
          filter: 'grayscale(1) brightness(0.5) blur(1px)',
          overflow: 'hidden',
        }}
      ></Box>
      {content?.description && (
        <Box
          sx={{
            height: 320,
            p: 2,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            color: 'bluishGrey.one',
            textAlign: 'left',
            alignItems: 'flex-start',
          }}
        >
          <Box>
            <Typography
              component='p'
              variant='body2'
              sx={{
                opacity: 0,
                transform: 'translateY(100%)',
                transition: 'all 300ms cubic-bezier(0.4,0,0.2,1)',
              }}
            >
              {Parser(content.description)}
            </Typography>
          </Box>
        </Box>
      )}
      {content?.caption && (
        <Box
          sx={{
            p: 2,
            height: 54,
            textAlign: 'left',
            backgroundColor: '#FDFDFD',
            position: 'relative',
            zIndex: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography component='h5' variant='body1Bold'>
            {content.caption}
          </Typography>
        </Box>
      )}
    </Card>
  )
}
export default BackgroundCardIspo
