import juryTextile1 from '../assets/images/ispo/jury-textile/braz-costa.jpg'
import juryTextile2 from '../assets/images/ispo/jury-textile/giusy-bettoni.jpg'
import juryTextile4 from '../assets/images/ispo/jury-textile/louisa-smith.jpg'
import juryTextile5 from '../assets/images/ispo/jury-textile/thomas-hakansson.jpg'
import juryTextile9 from '../assets/images/ispo/jury-textile/kutay-saritosun.jpg'
import categoryTextile1 from '../assets/images/ispo/categories-textile/bl52-eco-design-naia-acatel-acabamentos-texteis-sa-01.jpg'
import categoryTextile2 from '../assets/images/ispo/categories-textile/sl32-sorona-agile-dengguang-dg-0337-covation-biomaterials-usa-llc-01.jpg'
import categoryTextile3 from '../assets/images/ispo/categories-textile/ol35-fenc-hygroscopic-deformation-far-eastern-new-century-corp-01.jpg'
import categoryTextile4 from '../assets/images/ispo/categories-textile/mc2-a-heat-back-fabric-anta-china-co-ltd-01.jpg'
import categoryTextile5 from '../assets/images/ispo/categories-textile/ss24-hs22-02-0223-hyosung-01.jpg'
import categoryTextile6 from '../assets/images/ispo/categories-textile/se6-n1084-br-nupalette-ht-honmyue-enterprise-co-ltd-01.jpg'
import categoryTextile7 from '../assets/images/ispo/categories-textile/in8-expedry-ultra-dry-down-allied-feather-down-02.jpg'
import categoryTextile8 from '../assets/images/ispo/categories-textile/t26-el74060-35-jml-02.jpg'
import categoryTextile9 from '../assets/images/ispo/categories-textile/a11-3t-story-tag-3t-transfers-technologies-for-textile-02.jpg'
import categoryTextile10 from '../assets/images/ispo/categories-textile/beachwear-product-image-01.jpg'
import crossCategory1 from '../assets/images/ispo/categories-textile/ae-n4446-a3207-synnix-industries-inc-03.jpg'
import crossCategory2 from '../assets/images/ispo/categories-textile/performing-finishes-product-image-01.jpg'
import howToApply from '../assets/images/ispo/how-to-apply-background-image.jpg'
export const textileContent = (t) => {
  return {
    carousel: {
      className: 'swiper-juri swiper-navigation-green',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      slides: [
        {
          name: t('JURI_TEXTILE_9'),
          job: t('JURI_TEXTILE_9_JOB'),
          img: juryTextile9,
          info: t('JURI_TEXTILE_9_DESCRIPTION'),
        },
        {
          name: t('JURI_TEXTILE_1'),
          job: t('JURI_TEXTILE_1_JOB'),
          img: juryTextile1,
          info: t('JURI_TEXTILE_1_DESCRIPTION'),
        },
        {
          name: t('JURI_TEXTILE_2'),
          job: t('JURI_TEXTILE_2_JOB'),
          img: juryTextile2,
          info: t('JURI_TEXTILE_2_DESCRIPTION'),
        },
        {
          name: t('JURI_TEXTILE_4'),
          job: t('JURI_TEXTILE_4_JOB'),
          img: juryTextile4,
          info: t('JURI_TEXTILE_4_DESCRIPTION'),
        },
        {
          name: t('JURI_TEXTILE_5'),
          job: t('JURI_TEXTILE_5_JOB'),
          img: juryTextile5,
          info: t('JURI_TEXTILE_5_DESCRIPTION'),
        },
      ],
    },
    applyBrochure: {
      title: t('HOW_TO_APPLY'),
      text: t('HOW_TO_APPLY_STEPS'),
      subhead: t('APPLICATIONS_ARE_OPEN'),
      bgImg: false,
      backgroundImage: howToApply,
      extraClass: 'apply-container',
      buttons: [
        {
          text: t('DISCOVER_BROCHURE'),
          href: 'https://s3.eu-central-1.amazonaws.com/static-prod-fra.foursource.com/public/files/ISPO_TT_FallWinter_2526.pdf',
          color: 'white',
          variant: 'outlined',
          target: '_blank',
          rel: 'noreferrer',
        },
      ],
    },
    slider: {
      className: 'swiper-cols-4',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      loop: true,
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      slides: [
        {
          background: categoryTextile1,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_1'),
          description: t('CATEGORY_1_DESCRIPTION'),
        },
        {
          background: categoryTextile2,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_2'),
          description: t('CATEGORY_2_DESCRIPTION'),
        },
        {
          background: categoryTextile3,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_3'),
          description: t('CATEGORY_3_DESCRIPTION'),
        },
        {
          background: categoryTextile4,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_4'),
          description: t('CATEGORY_4_DESCRIPTION'),
        },
        {
          background: categoryTextile5,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_5'),
          description: t('CATEGORY_5_DESCRIPTION'),
        },
        {
          background: categoryTextile6,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_6'),
          description: t('CATEGORY_6_DESCRIPTION'),
        },
        {
          background: categoryTextile7,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_7'),
          description: t('CATEGORY_7_DESCRIPTION'),
        },
        {
          background: categoryTextile8,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_8'),
          description: t('CATEGORY_8_DESCRIPTION'),
        },
        {
          background: categoryTextile9,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_9'),
          description: t('CATEGORY_9_DESCRIPTION'),
        },
        {
          background: categoryTextile10,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('CATEGORY_10'),
          description: t('CATEGORY_10_DESCRIPTION'),
        },
      ],
    },
    crossCategorySlider: {
      className: 'swiper-cols-4 swiper-center',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      navigation: true,
      slides: [
        {
          background: crossCategory1,
          description: t('CROSS_CATEGORY_1_DESCRIPTION'),
          caption: t('CROSS_CATEGORY_1'),
        },
        {
          background: crossCategory2,
          description: t('CROSS_CATEGORY_2_DESCRIPTION'),
          caption: t('CROSS_CATEGORY_2'),
        },
      ],
    },
    fiveCriteria: {
      headers: [t('CRITERIA'), t('DEFINITION'), t('PROCEDURE')],
      rows: [
        {
          criteria: t('CRITERIA_1'),
          definition: t('CRITERIA_DEFINITION_1'),
          procedure: t('CRITERIA_PROCEDURE_1'),
        },
        {
          criteria: t('CRITERIA_2'),
          definition: t('CRITERIA_DEFINITION_2'),
          procedure: t('CRITERIA_PROCEDURE_2'),
        },
        {
          criteria: t('CRITERIA_3'),
          definition: t('CRITERIA_DEFINITION_3'),
          procedure: t('CRITERIA_PROCEDURE_3'),
        },
        {
          criteria: t('CRITERIA_4'),
          definition: t('CRITERIA_DEFINITION_4'),
          procedure: t('CRITERIA_PROCEDURE_4'),
        },
        {
          criteria: t('CRITERIA_5'),
          definition: t('CRITERIA_DEFINITION_5'),
          procedure: t('CRITERIA_PROCEDURE_5'),
        },
      ],
    },
    ecoCriteria: {
      headers: [],
      rows: [
        {
          criteria: t('CRITERIA_6'),
          definition: t('CRITERIA_DEFINITION_6'),
          procedure: t('CRITERIA_PROCEDURE_6'),
        },
      ],
    },
    performingCriteria: {
      headers: [],
      rows: [
        {
          criteria: t('CRITERIA_7'),
          definition: t('CRITERIA_DEFINITION_7'),
          procedure: t('CRITERIA_PROCEDURE_7'),
        },
      ],
    },
    benefitsTable: {
      headers: [t('BEST_PRODUCT'), t('TOP_TEN'), t('SELECTION')],
      rows: [
        {
          name: t('BENEFITS_1'),
          tooltip: t('BENEFITS_1_TOOLTIP'),
          best_product: true,
          top_ten: false,
          selection: false,
        },
        {
          name: t('BENEFITS_2'),
          tooltip: t('BENEFITS_2_TOOLTIP'),
          best_product: false,
          top_ten: true,
          selection: false,
        },
        {
          name: t('BENEFITS_3'),
          tooltip: t('BENEFITS_3_TOOLTIP'),
          best_product: false,
          top_ten: false,
          selection: true,
        },
        {
          name: t('BENEFITS_4'),
          tooltip: t('BENEFITS_4_TOOLTIP'),
          best_product: true,
          top_ten: false,
          selection: false,
        },
        {
          name: t('BENEFITS_5'),
          tooltip: t('BENEFITS_5_TOOLTIP'),
          best_product: false,
          top_ten: true,
          selection: false,
        },
        {
          name: t('BENEFITS_6'),
          tooltip: t('BENEFITS_6_TOOLTIP'),
          best_product: true,
          top_ten: false,
          selection: false,
        },
        {
          name: t('BENEFITS_7'),
          tooltip: t('BENEFITS_7_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: false,
        },
        {
          name: t('BENEFITS_8'),
          tooltip: t('BENEFITS_8_TOOLTIP'),
          best_product: false,
          top_ten: false,
          selection: true,
        },
        {
          name: t('BENEFITS_9'),
          tooltip: t('BENEFITS_9_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_10'),
          tooltip: t('BENEFITS_10_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_11'),
          tooltip: t('BENEFITS_11_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_12'),
          tooltip: t('BENEFITS_12_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_13'),
          tooltip: t('BENEFITS_13_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_14'),
          tooltip: t('BENEFITS_14_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_15'),
          tooltip: t('BENEFITS_15_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('BENEFITS_16'),
          tooltip: t('BENEFITS_16_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
      ],
      last: [t('OVERVIEW_OF_COSTS'), t('BEST_PRODUCT_PRICE'), t('TOP_TEN_PRICE'), t('SELECTION_PRICE')],
    },
  }
}
