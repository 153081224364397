import * as React from 'react'
import Parser from 'html-react-parser'

// Styles
import './benefitsTable.scss'
import * as S from './BenefitsTable.styles'

// Icons
import CheckIcon from '@mui/icons-material/Check'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

// Components
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

const BenefitsTable = ({ content }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 800 }} aria-label='simple table' className='criteria-table'>
        {content.headers.length > 0 && (
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {content.headers.map((header, i) => (
                <TableCell key={i} className='fw-bold header-blue'>
                  <Typography component='div' variant='h4'>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {content.rows.map((row, i) => (
            <TableRow key={i}>
              <TableCell component='th' scope='row' className='name-column d-flex align-items-center'>
                <div className='flex-fill pe-s-8'>{row.name}</div>
                <S.DarkTooltip
                  title={
                    <>
                      <Typography component='div' variant='body1Bold' sx={{ mb: 2 }}>
                        {row.name}
                      </Typography>
                      <Typography variant='body3Bold'>{row.tooltip}</Typography>
                    </>
                  }
                >
                  <IconButton size='small' sx={{ fontSize: 18, ml: 0.5 }}>
                    <HelpOutlineIcon />
                  </IconButton>
                </S.DarkTooltip>
              </TableCell>
              <TableCell className='check-column border-right'>
                {row.best_product ? <CheckIcon sx={{ color: 'primary.main', fontSize: 28 }} /> : '-'}
              </TableCell>
              <TableCell className='check-column border-right'>
                {row.top_ten ? <CheckIcon sx={{ color: 'primary.main', fontSize: 28 }} /> : '-'}
              </TableCell>
              <TableCell className='check-column'>
                {row.selection ? <CheckIcon sx={{ color: 'primary.main', fontSize: 28 }} /> : '-'}
              </TableCell>
            </TableRow>
          ))}
          <TableRow key={'last'}>
            {content.last.map((last, i) => (
              <TableCell key={i} scope='row' className='fw-bold bottom-blue'>
                <Typography component='div' variant='h4'>
                  {Parser(last)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BenefitsTable
