import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { Typography } from '@mui/material'

// Styles
import 'react-slideshow-image/dist/styles.css'
import './juryCard.scss'

const JuryCard = ({ content }) => {
  const { t } = useTranslation()

  return (
    <a href={content.link} target='_blank' rel='noreferrer'>
      <div className='juryCard'>
        <div className='juryCardFirstRow pt-s-12 pb-s-14 ps-s-16 pe-s-16 d-flex flex-row'>
          <div className='d-flex flex-column ellipsis align-items-start'>
            <b className='font-size-14 text-grey-10 ellipsis'>{content.name}</b>
            <span className='font-size-14 text-grey ellipsis'>{content.job}</span>
          </div>
        </div>
        <div className='juryCardProductImg position-relative'>
          <img src={content.img} alt={content.name} />
          {content.topImage && (
            <div className='position-absolute text-top-image text-white text-start ps-s-12 fw-bold'>
              <Typography variant='subhead' className='text-white m-0 pb-s-8'>
                {t('EXPERTISE')}
              </Typography>
              {content.topImage}
            </div>
          )}
        </div>
        <div className='d-flex flex-column pt-s-16 pb-s-16 ps-s-16 pe-s-16 text-start'>
          <span className='font-size-14 text-grey-10 ln-16 pb-s-8'>{content.info}</span>
        </div>
      </div>
    </a>
  )
}

export default JuryCard
